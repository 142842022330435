<template>
  <div class="wap-mine-setting">
    <headerBar :title="$t('dian-pu-she-zhi-0')" @back="back"></headerBar>
    <div class="menu-list">
      <div
        class="menu flex-center-between"
        v-for="(item, i) in menuList"
        :key="i"
        @click="toPath(item.path)"
      >
        <div class="flex-center">
          <img :src="item.icon" alt="" />
          <span>{{ item.name }}</span>
        </div>
        <div>
          <i class="el-icon-arrow-right"></i>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import headerBar from '@/components/header'
export default {
  components: {
    headerBar
  },
  data() {
    return {}
  },
  computed: {
    menuList() {
      return [
        {
          icon: require('@/assets/imgs/icon-setting1.png'),
          name: this.$t('ji-chu-xin-xi-0'),
          path: 'wapBasicInfo'
        },
        {
          icon: require('@/assets/imgs/icon-setting2.png'),
          name: this.$t('shou-ye-heng-fu'),
          path: 'wapShopBanner'
        },
        // {
        //   icon: require('@/assets/imgs/icon-setting3.png'),
        //   name: this.$t('she-jiao-mei-ti'),
        //   path: 'wapShopSocial'
        // },
        // {
        //   icon: require('@/assets/imgs/icon-setting4.png'),
        //   name: this.$t('dian-zi-he-tong'),
        //   path: ''
        // }
      ]
    }
  },
  methods: {
    toPath(path) {
      this.$router.push({
        name: path
      })
    },
    back() {
      this.$router.go(-1)
    }
  }
}
</script>